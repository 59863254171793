<template>
  <LayoutHorizontalCore>
    <router-view />

    <template #navbar="{ toggleVerticalMenuActive }">
      <Navbar :toggle-vertical-menu-active="toggleVerticalMenuActive" />
    </template>

  </LayoutHorizontalCore>
</template>

<script>
// import LayoutHorizontal from '@core/layouts/layout-horizontal/LayoutHorizontal.vue';
// import AppCustomizer from '@core/layouts/components/app-customizer/AppCustomizer.vue'
// import { $themeConfig } from '@themeConfig'
// import AppNavbarHorizontalLayoutBrand from '@core/layouts/components/app-navbar/AppNavbarHorizontalLayoutBrand.vue';
//import Navbar from '../components/Navbar.vue';

export default {
  name: 'LayoutHorizontal',

  components: {
    LayoutHorizontalCore: () => import('@core/layouts/layout-horizontal/LayoutHorizontalCore.vue' /* webpackChunkName: "LayoutHorizontalCore" */),
    Navbar: () => import('@/layouts/components/Navbar.vue' /* webpackChunkName: "Navbar" */),
    // AppNavbarHorizontalLayoutBrand,
  },
  data() {
    return {
      // showCustomizer: $themeConfig.layout.customizer,
    };
  },
};
</script>
